import { default as creator } from './creator';
import { default as web3 } from './web3';
import { default as home } from './home';
import { default as faq } from './faq';
import { default as world } from './world';
import { default as digitalGuidelines } from './digitalGuidelines';

const nav = {
  home: 'Home',
  creator: 'for Creator',
  web3: 'for Web3',
  world: 'Discover World',
  faq: 'FAQ',
  signin: 'Sign in',
};


const footer = {
  cietyDigitalGuidelines: "사이어티 디지털 가이드라인",
  privacyPolicy: "개인정보 처리 방침",
  integratedPaidServiceTerms: "통합 유료서비스 이용약관"

}
export const translation = {
  nav,
  footer,
  digitalGuidelines,
  creator,
  web3,
  home,
  faq,
  world,
};
