export default {
  title: 'CITEY Digital Guidelines – Child Safety Policy',
  description: 'Ciety strives to ensure that all users can safely participate in our community. In particular, we prioritize the safety of children and adolescents above all else, and we apply a zero-tolerance principle to sexual offenses targeting minors. Accordingly, if any content or activities that encourage such offenses or related behaviors are identified, the account and its access to services will be immediately and permanently restricted, and we will take active measures, such as reporting to law enforcement, if necessary.',
  guidelines: [
    {
      title: '1. Principles for Protecting Children and Adolescents',
      content:
        '<ul><li>Ciety <strong>prohibits users under the age of 14</strong> from joining.</li>' +
        '<li>Users under 18 may have restricted access to certain content and features.</li>' +
        '<li>We take care to ensure that children do not provide personal information without the consent of a parent or guardian.</li></ul>'
    },
    {
      title: '2. Zero-Tolerance Policy for Sexual Crimes Against Children and Adolescents',
      description: 'Ciety applies a <strong>zero-tolerance policy</strong> to sexual crimes targeting children and adolescents. If you violate these related policies, your account will be <strong>permanently restricted from the service immediately, regardless of the number of previous offenses,</strong> and we may also take <strong>active measures such as reporting to law enforcement</strong> when necessary. <br/><br/> The following acts are subject to immediate sanctions and legal action under this zero-tolerance principle:',
      subTitleContents:
        [
          '1) Activities Related to Child/Adolescent Sexual Exploitation Material' +
          '<ul>' +
          '<li>Providing or expressing the intent to provide child/adolescent sexual exploitation material in any form, including production, sale, rental, distribution, sharing, promotion, or advertising</li>' +
          '<li>Producing or distributing sexual exploitation material that clearly depicts or can be recognized as children or adolescents (including drawn or animated characters)</li>' +
          '<li>Transmitting such content via email, messaging, file-sharing, or any other method</li>' +
          '</ul>',

          '2) Possession and Use of Child/Adolescent Sexual Exploitation Material' +
          '<ul>' +
          '<li>Possessing, storing, sharing, viewing, or downloading sexual exploitation material produced with children or adolescents, with full knowledge of its content</li>' +
          '<li>Storing such content in online storage spaces (cloud services, community servers, etc.)</li>' +
          '</ul>',

          '3) Acts that Promote Sexual Crimes Against Children/Adolescents' +
          '<ul>' +
          '<li>Providing information, trading, brokering, or in any way aiding in the use of children or adolescents for the production of sexual exploitation material</li>' +
          '<li>Providing or expressing the intent to provide pornography or sexual exploitation material to children or adolescents</li>' +
          '<li>Engaging in sexual transactions with minors, or arranging, recommending, or inducing sexual acts with minors</li>' +
          '</ul>',

          '4) Conspiring to Commit Sexual Crimes Against Children/Adolescents and Sexual Objectification' +
          '<ul>' +
          '<li>Conspiring to commit sexual assault, forcible molestation, prostitution, or other crimes against children/adolescents, or depicting such activities for sexual stimulation</li>' +
          '<li>Creating and distributing posts, comments, images, videos, or composite works that sexually objectify children or adolescents</li>' +
          '</ul>',

          '5) Grooming Behavior Targeting Children/Adolescents' +
          '<ul>' +
          '<li>Establishing a close relationship with a child or adolescent for the purpose of sexual exploitation, inducing or coercing sexual acts</li>' +
          '<li>Any form of demanding sexual behavior, such as initiating sexual conversations, requesting meetings, or asking for explicit media</li>' +
          '</ul>',

          '6) Other Acts Encouraging Sexual Crimes Against Children/Adolescents' +
          '<ul>' +
          '<li>Offering a place for sexual crimes, soliciting or luring children/adolescents, or any other behavior that encourages sexual offenses against minors</li>' +
          '</ul>'
        ]
    },
    {
      title: '3. Reporting System and Response Measures',
      description: 'Ciety implements its policies for protecting children and adolescents based on user reports.',
      subTitleContents: [
        '1) Operation of the Reporting System' +
        '<ul>' +
        '<li>We provide a “Report” function within our service. Reports related to child/adolescent protection are received and addressed 24 hours a day, 365 days a year.</li>' +
        '<li>Any content or accounts that are reported are reviewed immediately, and if they are found to violate our policies, we promptly remove the content, suspend the account, and, if necessary, contact law enforcement.</li>' +
        '<li>The anonymity of the reporter is strictly protected.</li>' +
        '</ul>',

        '2) Operation of an Emergency Reporting System' +
        '<ul>' +
        '<li>Reports of child sexual crimes are reviewed immediately and given top priority.</li>' +
        '<li>Upon receiving a report, an automatic sanction feature is applied to temporarily suspend the problematic account, followed by permanent suspension and possible reporting to law enforcement after review.</li>' +
        '</ul>',

        '3) Preventive and Additional Measures' +
        '<ul>' +
        '<li>We analyze the data from reports and take proactive action against accounts that are repeatedly reported.</li>' +
        '</ul>'
      ]
    },
    {
      title: '4. Providing a Safe Interaction Environment',
      content:
        '<ul>' +
        '<li>Sharing personal information is prohibited in public communities, and posts violating this rule may be removed.</li>' +
        '<li>We operate a reporting and sanction system so that users can block malicious actors.</li>' +
        '</ul>'
    },
    {
      title: '5. Data Protection and Handling of Personal Information',
      content:
        '<ul>' +
        '<li>To protect children’s personal information, we limit targeted advertising and the collection of personal data.</li>' +
        '<li>At the request of a parent or guardian, we will assist with deleting a child’s account and removing any associated data.</li>' +
        '<li>If a child uses the service, parental guidance is required. We provide parental control features that can be set up by guardians.</li>' +
        '</ul>'
    },
    {
      title: '6. Actions for Policy Violations',
      content:
        '<ul>' +
        '<li>If you violate this child safety policy, we immediately remove the content and permanently suspend your account without prior warnings.</li>' +
        '<li>If the violation involves severe legal offenses, we may report the matter to the relevant authorities for further legal procedures.</li>' +
        '</ul><br/>' +
        '<div><strong>Ciety is dedicated to ensuring a safe online community for children and adolescents. We kindly request the active cooperation of all users and guardians.</strong></div>' +
        '<ul>' +
        '<li>For inquiries or to report an issue, please contact: <a href="mailto:contact@ciety.io">contact@ciety.io</a></li>' +
        '</ul>'
    },
    {
      title: '7. Designation of a Youth Protection Officer',
      description: 'A Youth Protection Officer is designated to manage and block harmful information for adolescents and to carry out tasks related to youth protection. We provide a consultation channel for harmful information via <a href="mailto:contact@ciety.io">contact@ciety.io</a>',
      subTitleContents: [
        'Youth Protection Officer' +
        '<ul>' +
        '<li>Name: 유인동</li>' +
        '<li>Department: CTO</li>' +
        '<li>Contact: <a href="mailto:contact@ciety.io">contact@ciety.io</a></li>' +
        '</ul>',

        'Youth Protection Manager' +
        '<ul>' +
        '<li>Name: 이효진</li>' +
        '<li>Department: CCO</li>' +
        '<li>Contact: <a href="mailto:contact@ciety.io">contact@ciety.io</a></li>' +
        '</ul>'
      ]
    }
  ]
}