import { default as creator } from './creator';
import { default as web3 } from './web3';
import { default as home } from './home';
import { default as faq } from './faq';
import { default as world } from './world';
import { default as digitalGuidelines } from './digitalGuidelines';

const nav = {
  home: 'Home',
  creator: 'for Creator',
  web3: 'for Web3',
  world: 'Discover World',
  faq: 'FAQ',
  signin: 'Sign in',
};

const footer = {
  cietyDigitalGuidelines: "Ciety Digital Guidelines",
  privacyPolicy: "Privacy Policy",
  integratedPaidServiceTerms: "Integrated Paid Service Terms"
}

export const translation = {
  nav,
  footer,
  digitalGuidelines,
  creator,
  web3,
  home,
  faq,
  world,
};
