import { createBrowserRouter } from 'react-router-dom';
import loadable from '@loadable/component';

import Web3 from '../pages/Web3';
import Layout from '../components/module/Layout';
import PreRenderedLoadable from './_PreRenderedLoadable';
import ErrorBoundary from '../components/module/ErrorBoundary';

const Privacy = loadable(() => import('../pages/Privacy'));
const Terms = loadable(() => import('../pages/Terms'));
const Worlds = loadable(() => import('../pages/Worlds'));
const Creator = loadable(() => import('../pages/creator'));
const Home = loadable(() => import('../pages/home'));
const Faq = loadable(() => import('../pages/faq'));
const Download = loadable(() => import('../pages/Download'));
const DigitalGuidelines = loadable(() => import('../pages/digitalGuidelines'));

export const router = createBrowserRouter([
  {
    path: '/',
    element: <Layout />,
    errorElement: <ErrorBoundary />,
    children: [
      {
        index: true,
        element: <Home />,
      },
      {
        path: 'creator',
        element: <PreRenderedLoadable loadableComp={Creator} />,
      },
      {
        path: 'web3',
        element: <Web3 />,
      },
      {
        path: 'worlds',
        element: <PreRenderedLoadable loadableComp={Worlds} />,
      },
      {
        path: 'faq',
        element: <PreRenderedLoadable loadableComp={Faq} />,
      },
      {
        path: 'privacy',
        element: <Privacy />,
      },
      {
        path: 'terms',
        element: <Terms />,
      },
      {
        path: 'download',
        element: <PreRenderedLoadable loadableComp={Download} />,
      },
      {
        path: 'digital-guidelines',
        element: <PreRenderedLoadable loadableComp={DigitalGuidelines}/>,
      },
    ],
  },
]);
