export default {
  title: '사이어티 디지털 가이드라인 – 아동 안전 정책',
  description: '사이어티는 모든 사용자가 안전하게 커뮤니티를 이용할 수 있도록 최선을 다합니다. 특히, 아동과 청소년의 안전을 최우선으로 고려하며, 아동·청소년. 대상 성범죄에 대해 무관용 원칙을 적용합니다. 이에 따라, 해당 범죄 및 관련 행위를 조장하는 콘텐츠나 활동이 확인될 경우, 즉시 계정과 서비스 이용을 영구적으로 제한하며, 필요 시 수사기관에 신고하는 등 적극적인 조치를 취합니다.',
  guidelines: [
    {
      title: '1. 아동 및 청소년 보호 원칙',
      content:
        '<ul><li>사이어티는 <strong>만 14세 미만 사용자의 가입을 제한</strong>합니다.</li>' +
        '<li>만 18세 미만 사용자의 경우, 특정 콘텐츠 및 기능에 대한 접근이 제한될 수 있습니다.</li>' +
        '<li>아동이 부모 또는 보호자의 동의 없이 개인 정보를 제공하지 않도록 주의합니다.</li></ul>'
    },
    {
      title: '2. 아동 및 청소년 대상 성범죄 무관용 원칙',
      description: '사이어티는 아동 및 청소년 대상 성범죄에 대해 <strong>무관용 원칙을 적용</strong>하며, 관련 정책을 위반하는 경우 <strong>누적 정도와 관계없이 즉시 해당 계정과 서비스 이용을 영구적으로 제한</strong>합니다. 또한, 필요시 <strong>수사기관 신고 등 적극적인 조치</strong>를 취합니다.<br/>다음과 같은 행위는 무관용 원칙에 따라 즉각적인 제재 및 법적 조치 대상이 됩니다.',
      subTitleContents:
        [
          '1) 아동·청소년 성착취물 관련 행위' +
          '<ul>' +
          '<li>제작, 판매, 대여, 배포, 공유, 홍보, 광고 등을 포함하여 어떠한 방식으로든 아동·청소년 성착취물을 제공하거나 제공하려는 의사를 표현하는 행위</li>' +
          '<li>아동·청소년으로 명백하게 인식될 수 있는 인물이나 표현물(작화 포함)이 등장하는 성착취물의 제작 및 유포</li>' +
          '<li>이메일, 메신저, 파일 공유 등을 통해 이러한 콘텐츠를 전송하는 행위</li>' +
          '</ul>',

          '2) 아동·청소년 성착취물 소지 및 이용 행위' +
          '<ul>' +
          '<li>아동·청소년을 이용하여 제작된 성착취물을 인지한 상태에서 소지, 저장, 공유, 열람, 다운로드하는 행위</li>' +
          '<li>온라인 저장 공간(클라우드, 커뮤니티 서버 등)에 해당 콘텐츠를 보관하는 행위</li>' +
          '</ul>',

          '3) 아동·청소년 대상 성범죄 조장 행위' +
          '<ul>' +
          '<li>정보 제공, 매매, 알선 등을 통해 아동·청소년이 성착취물 제작에 이용되도록 돕는 행위</li>' +
          '<li>아동·청소년에게 음란물 또는 성착취물을 제공하거나 제공할 의사를 표현하는 행위</li>' +
          '<li>아동·청소년의 성을 매매하거나, 성매매를 알선·권유·유도하는 행위</li>' +
          '</ul>',

          '4) 아동·청소년 대상 성범죄 모의 및 성적 대상화' +
          '<ul>' +
          '<li>아동·청소년을 대상으로 한 성폭행, 강제추행, 성매매 등 범죄를 모의하거나 성적 욕구를 자극할 목적으로 묘사하는 행위</li>' +
          '<li>아동·청소년을 성적 대상으로 표현하는 게시글, 댓글, 이미지, 영상, 합성물 제작 및 유포 행위</li>' +
          '</ul>',

          '5) 아동·청소년을 대상으로 한 그루밍(Grooming) 행위' +
          '<ul>' +
          '<li>성적 착취를 목적으로 아동·청소년과 친밀한 관계를 형성하여 성적 행위를 유도·강요하는 행위</li>' +
          '<li>성적인 대화, 만남 요구, 촬영물 요청 등 어떠한 형태로든 성적 행동을 요구하는 행위</li>' +
          '</ul>',

          '6) 아동·청소년 대상 성범죄를 조장하는 기타 행위' +
          '<ul>' +
          '<li>성범죄 장소 제공, 성범죄 권유 및 유인 등 아동·청소년 대상 성범죄를 조장하는 모든 행위</li>' +
          '</ul>'
        ]
    },
    {
      title: '3. 신고 시스템 및 대응 조치',
      description: '사이어티는 사용자의 신고를 기반으로 아동·청소년 보호 정책을 시행합니다.',
      subTitleContents: [
        '1) 신고 시스템 운영' +
        '<ul>' +
        '<li>서비스 내 ‘신고하기’ 기능을 제공하며, 아동·청소년 보호 관련 신고는 24시간 365일 접수 및 대응합니다.</li>' +
        '<li>신고된 콘텐츠 및 계정은 즉시 검토되며, 정책 위반이 확인될 경우 콘텐츠 삭제, 계정 정지, 수사기관 신고 등의 조치를 즉시 시행합니다.</li>' +
        '<li>제보자의 익명성을 철저히 보장합니다.</li>' +
        '</ul>',

        '2) 긴급 신고 시스템 운영' +
        '<ul>' +
        '<li>아동 성범죄 관련 신고는 즉시 검토 및 우선 처리됩니다.</li>' +
        '<li>신고 후 즉시 자동 제재 기능을 적용하여 문제 계정을 임시 정지하고, 검토 후 영구 정지 및 수사기관 신고 조치를 진행합니다.</li>' +
        '</ul>',

        '3) 사전 예방 및 추가 조치' +
        '<ul>' +
        '<li>신고 데이터를 분석하여 반복적으로 신고되는 계정은 선제적으로 조치합니다.</li>' +
        '</ul>'
      ]
    },
    {
      title: '4. 안전한 상호작용 환경 제공',
      content:
        '<ul>' +
        '<li>공개 커뮤니티에서는 개인정보 공유를 금지하며, 이를 위반하는 경우 게시물이 삭제될 수 있습니다.</li>' +
        '<li>악의적인 사용자를 차단할 수 있도록 신고 및 제재 시스템을 운영합니다.</li>' +
        '</ul>'
    },
    {
      title: '5. 데이터 보호 및 개인정보 처리',
      content:
        '<ul>' +
        '<li>아동의 개인정보 보호를 위해 광고 타겟팅 및 개인 데이터 수집을 제한합니다.</li>' +
        '<li>부모 또는 보호자의 요청이 있을 경우, 아동 계정 삭제 및 데이터 삭제 요청을 지원합니다.</li>' +
        '<li>아동이 이용할 경우 보호자의 지도가 필요하며, 보호자가 설정할 수 있는 부모 관리 기능을 제공합니다.</li>' +
        '</ul>'
    },
    {
      title: '6. 위반 시 조치',
      content:
        '<ul>' +
        '<li>아동 안전 정책을 위반하는 경우, 누적 경고 없이 즉시 콘텐츠 삭제 및 계정 영구 정지 조치가 이루어집니다.</li>' +
        '<li>심각한 법적 위반 사항이 포함된 경우 관계 당국에 신고하여 추가 법적 절차를 진행할 수 있습니다.</li>' +
        '</ul><br/>' +
        '<div><strong>사이어티는 아동·청소년이 안전하게 온라인 커뮤니티를 이용할 수 있도록 최선을 다하고 있으며, 모든 사용자와 보호자의 적극적인 협조를 부탁드립니다.</strong></div>' +
        '<ul>' +
        '<li>문의 및 신고: <a href="mailto:contact@ciety.io">contact@ciety.io</a></li>' +
        '</ul>'
    },
    {
      title: '7. 청소년 보호 책임자 지정',
      description: '청소년보호책임자를 지정해 청소년 유해 정보의 차단 및 관리 등 청소년 보호 업무를 수행하고 있으며, <a href="mailto:contact@ciety.io">contact@ciety.io</a>를 통해 유해 정보에 관한 상담 창구를 제공하고 있습니다.',
      subTitleContents: [
        '청소년 보호 책임자' +
        '<ul>' +
        '<li>이름: 유인동</li>' +
        '<li>부서명: CTO</li>' +
        '<li>연락처: <a href="mailto:contact@ciety.io">contact@ciety.io</a></li>' +
        '</ul>',

        '청소년 보호 담당자' +
        '<ul>' +
        '<li>이름: 이효진</li>' +
        '<li>부서명: CCO</li>' +
        '<li>연락처: <a href="mailto:contact@ciety.io">contact@ciety.io</a></li>' +
        '</ul>'
      ]
    }
  ]
}