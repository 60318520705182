import styl from './styles.module.scss';
import { Link } from 'react-router-dom';
import { ReactGA } from '../../utils/ga';
import { Links } from '../../utils/outLinks';
import { LogoSymbol } from '../svg';
import {useTranslation} from "react-i18next";
import {useAtom} from "jotai/index";
import {isMobileAtom} from "@/logics/atom";

export default function Footer() {
  const { t } = useTranslation();
  const [isMobile] = useAtom(isMobileAtom);

  return (
    <footer>
      <div className={styl.footerContainer}>
        <div className={styl.top}>
          <div className={styl.slogan}>
            <h3>
              Hello. We are CIETY,
              <br />
              building a new world for communities
              <br />
              and the creator economy.
            </h3>
          </div>
          <div className={styl.groups}>
            <div className={styl.group}>
              <h4>CIETY</h4>
              <ul>
                <li>
                  <ReactGA.OutboundLink
                    eventLabel="outlink"
                    to={Links.community}
                  >
                    Community
                  </ReactGA.OutboundLink>
                </li>
                <li>
                  <ReactGA.OutboundLink
                    eventLabel="outlink"
                    to={Links.contact}
                    target="_blank"
                  >
                    Contact
                  </ReactGA.OutboundLink>
                </li>
              </ul>
            </div>
            <div className={styl.group}>
              <h4>Follow us</h4>
              <ul>
                <li>
                  <ReactGA.OutboundLink
                    eventLabel="outlink"
                    to={Links.twitter}
                    target="_blank"
                  >
                    Twitter
                  </ReactGA.OutboundLink>
                </li>
                <li>
                  <ReactGA.OutboundLink
                    eventLabel="outlink"
                    to={Links.instagram}
                    target="_blank"
                  >
                    Instagram
                  </ReactGA.OutboundLink>
                </li>
                <li>
                  <ReactGA.OutboundLink
                    eventLabel="outlink"
                    to={Links.linkedIn}
                    target="_blank"
                  >
                    Linkedin
                  </ReactGA.OutboundLink>
                </li>
              </ul>
            </div>
            <div className={styl.group}>
              <h4>Content</h4>
              <ul>
                <li>
                  <ReactGA.OutboundLink
                    eventLabel="outlink"
                    to={Links.medium}
                    target="_blank"
                  >
                    Medium
                  </ReactGA.OutboundLink>
                </li>
                <li>
                  <ReactGA.OutboundLink
                    eventLabel="outlink"
                    to={Links.youtube}
                    target="_blank"
                  >
                    Youtube
                  </ReactGA.OutboundLink>
                </li>
                <li>
                  <ReactGA.OutboundLink
                    eventLabel="outlink"
                    to={Links.friends}
                    target="_blank"
                  >
                    Opensea
                  </ReactGA.OutboundLink>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className={styl.logo}>
          <Link to={'/'}>
            <LogoSymbol />
          </Link>
        </div>
        <div className={styl.etc}>
          <p>&copy; CIETY. All right reserved.</p>
          <div className={styl.right}>
            <div className={styl.links}>
              <Link to={'/digital-guidelines'}>{t('footer.cietyDigitalGuidelines')}</Link>
              <Link to={'/privacy'}>{t('footer.privacyPolicy')}</Link>
              {isMobile && (
                <br/>
              )}
              <Link to={'/terms'}>{t('footer.integratedPaidServiceTerms')}</Link>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
